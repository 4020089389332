interface Category {
  title: string;
  description: string;
  imageURL?: string;
}

const categories: Array<Category> = [
  {
    title: "Healthcare-IT",
    description:
      "According to recent data, healthcare-IT is tipped to become a $390 billion industry by 2024. From health information exchanges (HIEs), through to virtual consultations, our entrepreneurs focus on solving some of the sector's greatest challenges by providing solutions that support healthcare professionals to make more insightful decisions through enhanced data collection, interpretation and communication.",
    imageURL: "/category-it.png",
  },
  {
    title: "Big Data Analytics",
    description:
      "Big data analytics has changed the way we understand many industries around the world and healthcare is no exception. Through the careful management, understanding and processing of certain data points, the healthcare industry is not only able to form more accurate models that may prevent future pandemics, but also reduce a broad range of operational and production costs, while saving lives.",
    imageURL: "/category-bigdata.png",
  },
  {
    title: "Augmented /<br/> Virtual Reality",
    description:
      "Thanks in part to the power of enhanced communication technologies such as 5G, AR and VR have a considerable role to play in the future of healthcare, ranging from superficial marketing apps such as 'virtually' trying on new glasses, through to remote training, patient treatment and disease awareness. Estimated to be valued at $2.4 billion by 2026, the AR/VR industry is likely to be one of the most high-impact technology sectors in the coming years.",
    imageURL: "/category-vr.png",
  },
  {
    title: "Patient Monitoring &<br/>Digital Therapeutics",
    description:
      "Through the wider use of Internet of Things (IoT) technology, patient monitoring has been able to go beyond just observing physiological measurements such as heart rate and blood pressure and into a new era that seeks to understand behavioural traits such as medication compliance and allowing for monitoring in the home and workplace, significantly transforming delivery of healthcare. Advances in digital health will soon see digital therapeutics become a core part of medicine in conjunction with hardware and drug-based treatments.",
    imageURL: "/category-therapeutics.png",
  },
  {
    title: "Diagnostics & Imaging",
    description:
      "As a sector which is in the middle of a transition from 2D to 3D, the diagnostics and imaging market will benefit from various developments including AI-driven analytics to improve accuracy and speed of decision making, augmented intelligence to improve repetitive manual processes and nuclear imaging to support in the diagnosis of conditions such as thyroid diseases, heart conditions and cancer.",
    imageURL: "/category-imaging.png",
  },
  {
    title: "Medical Devices<br/> / Wearables",
    description:
      "While several major multinational corporations have already entered the medical devices/ wearables market, recent statistics show that in 2019 it was worth $456.9 billion and projected to reach $603 billion in 2023, with a greater emphasis on apparel integration, a wider array of sensors and the development of the implants market, which will soon become integrated with remote monitoring technology, while adhering to strict privacy standards.",
    imageURL: "/category-wearables.png",
  },
  {
    title: "3D Printing",
    description:
      "3D printing has already shown to be an enormous opportunity for the medical industry estimated worth $1.3 billion growing to $6 billion by 2027, with its key applications including medical/ surgical devices or instruments. In addition, 3D bioprinting has already been used to create living human cells and tissue in regenerative medicine and tissue engineering, while in the world of medicine it helps manufacture precision and personalized pharmaceutics.",
    imageURL: "/category-3dprinting.png",
  },
  {
    title: "Genomics &<br /> Precision Medicine",
    description:
      "Thanks to the significantly reduced cost of DNA sequencing through to a greater understanding of genetic diseases, genomics and tailored precision medicine will soon include the functional knowledge of each gene's role, how our environment affects our gene expression, the widespread integration of genome testing and the eradication of commonplace diseases.",
    imageURL: "/category-genomics.png",
  },
  {
    title: "Artificial Intelligence <br/>& Machine Learning",
    description:
      "Having already attracted a significant volume of investment, the potential benefits of AI in healthcare are enormous, ranging from improving drug discovery, helping to predict the early diagnosis of diseases such as Alzheimer's through to absorbing patient data and using it to produce useful algorithms that may help to provide life-saving insights while reducing the need for costly tests and procedures.",
    imageURL: "/category-ai.png",
  },
  {
    title: "Sensors, Robotics<br/> & Prosthetics",
    description:
      "As a field that has harnessed the imagination of mankind for almost a century, the evolution of sensors and robotics, playing an increasing role in our medical world is a fascinating one, ranging from assessments and logistics, through to a new frontier of prosthetics that interface brains with computers to change the way those with either physical or neurological damage are able to operate and function.",
    imageURL: "/category-prosthetics.png",
  },
  {
    title: "Telehealth / mHealth",
    description:
      "Spurred on by the recent pandemic, Telehealth has seen a massive period of growth, with one source claiming that telehealth news mentions tripled between February and April 2021. Subsequently, the industry's investment market is on track to exceed 2020's investments, which achieved $6.6bn across 490 deals in the US. As of May 2021, $2.6bn has already been raised across 194 deals.",
    imageURL: "/category-telehealth.png",
  },
  {
    title: "Tools & Platforms",
    description:
      "Since the advent of behavioural phenotyping, the development of new tools and platforms that are tailored around individuals offer a massive upside in understanding the full scope of the patient, meaning a wider array of factors can both be learned and understood, thereby helping to make both long and short term treatments more effective.",
    imageURL: "/category-platforms.png",
  },
];

export { categories };
