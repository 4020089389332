import Link from "next/link";
import { PropsWithChildren } from "react";
import classes from "./button.module.css";

interface ButtonContainerProps {
  classList?: Array<string>;
  style?: any;
}

interface ButtonProps {
  buttonText: string;
  buttonHref: string;
  isWhite?: boolean;
  isBlue?: boolean;
  isSmall?: boolean;
  isWide?: boolean;
  style?: any;
  className?: string;
}

const ButtonContainer = ({
  children,
  classList,
  style,
}: PropsWithChildren<ButtonContainerProps>) => {
  classList = classList || [];
  style = style || {};

  const classStrings = [classes.marketing__button_container, ...classList];
  return (
    <div className={classStrings.join(" ")} style={style}>
      {children}
    </div>
  );
};

const Button = ({
  buttonText,
  buttonHref,
  isWhite,
  isBlue,
  isSmall,
  isWide,
  style,
  className,
}: PropsWithChildren<ButtonProps>) => {
  const buttonClasses = [className ?? "", classes.button];
  if (isWhite) buttonClasses.push(classes.button_white);
  if (isBlue) buttonClasses.push(classes.button_blue);
  if (isSmall) buttonClasses.push(classes.button_small);
  if (isWide) buttonClasses.push(classes.button_wide);

  style = style || {};

  return (
    <Link href={buttonHref} style={style} className={buttonClasses.join(" ")}>
      {buttonText}
    </Link>
  );
};

export { Button, ButtonContainer };
