import { Button } from "../button";
import { Container } from "../container";
import { IconLogoMarkDark, IconLogoMarkWhite } from "../icons";
import classes from "./cta.module.css";

interface CtaProps {
  title: string;
  description?: string;
  buttonText: string;
  buttonHref: string;
  isBlue?: boolean;
}

const Cta = ({
  title,
  description,
  buttonText,
  buttonHref,
  isBlue,
}: CtaProps) => {
  const classList = [classes.marketing__cta];
  if (isBlue) classList.push(classes.marketing__cta_blue);

  let tailIcon = (
    <IconLogoMarkDark className={classes.marketing_cta_tailicon} />
  );
  if (isBlue) {
    tailIcon = <IconLogoMarkWhite className={classes.marketing_cta_tailicon} />;
  }

  return (
    <section className={classList.join(" ")}>
      <Container>
        <h2>{title}</h2>
        <p>{description}</p>
        <Button
          buttonText={buttonText}
          buttonHref={buttonHref}
          isWhite
          isWide
        />
      </Container>
      {tailIcon}
    </section>
  );
};

export { Cta };
