import { createRef, useState } from "react";
import type ReCAPTCHAV2 from "react-google-recaptcha";
import { api } from "../../../lib/api";
import { NewsletterSignupProps } from "../../../lib/mail/templates/newsletter-signup/template";
import { ReCAPTCHA } from "../../recaptcha";
import { Container } from "../container";
import classes from "./newsletter.module.css";

interface NewsletterProps {
  hasBlueDivider?: boolean;
}

const Newsletter = ({ hasBlueDivider }: NewsletterProps) => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [reCaptcha, setReCaptcha] = useState("");

  const [notice, setNotice] = useState("");

  const handleNameChange = (event) => setUserName(event.target.value);
  const handleEmailChange = (event) => setUserEmail(event.target.value);

  const recaptchaRef = createRef<ReCAPTCHAV2>();

  const validate = () => {
    if (!userName) return false;
    if (!userEmail) return false;

    return true;
  };

  const sendToApi = async () => {
    if (!validate()) return false;
    // @ts-ignore
    const recaptchaRes = await recaptchaRef?.current?.executeAsync();
    console.log("recaptchaRes", recaptchaRes);
    // @ts-ignore
    setReCaptcha(recaptchaRes);

    const requestBody: NewsletterSignupProps = {
      name: userName,
      email: userEmail,
    };

    await api.mutation("enquiry.newsletter", {
      ...requestBody,
      recaptcha_token: recaptchaRes as unknown as string,
    });

    // Reset the form.
    setUserName("");
    setUserEmail("");
    // Show a UI notice.
    setNotice("Thank you for signing up");
  };

  const classList = [classes.marketing__newsletter];
  if (hasBlueDivider) classList.push(classes.marketing__newsletter_bluedivider);

  return (
    <div className={classList.join(" ")}>
      <Container>
        {notice && <div className={classes.notice}>{notice}</div>}
        <div className={classes.marketing__newsletter_columns}>
          <div>
            <ReCAPTCHA
              invisible
              ref={recaptchaRef}
              recaptchaToken={reCaptcha}
              type="v2"
            />
            <label>Newsletter Signup:</label>
          </div>
          <div>
            <input
              type="text"
              value={userName}
              placeholder="Name"
              onChange={handleNameChange}
            />
          </div>
          <div>
            <input
              type="email"
              value={userEmail}
              placeholder="Email Address"
              onChange={handleEmailChange}
            />
          </div>
          <div>
            <button onClick={() => sendToApi()} disabled={!validate()}>
              Sign Up
            </button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export { Newsletter };
