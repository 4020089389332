import { forwardRef, useState } from "react";
import ReCAPTCHAV2 from "react-google-recaptcha";

export const ReCAPTCHA = forwardRef<
  ReCAPTCHAV2,
  { type: "v3" | "v2"; recaptchaToken?: string; invisible?: boolean }
>(({ type, recaptchaToken, invisible = false }, ref) => {
  const [recaptchaTokenV2, setRecaptchaTokenV2] = useState<string | undefined>(
    recaptchaToken
  );

  if (type === "v2")
    return (
      <>
        <input
          type="hidden"
          id="recaptcha-token-v2"
          name="recaptcha-token-v2"
          defaultValue={recaptchaTokenV2}
          onChange={() => {}}
        />
        <ReCAPTCHAV2
          ref={ref}
          size={invisible ? "invisible" : "normal"}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            marginBottom: "1rem",
          }}
          sitekey={
            invisible
              ? process.env.NEXT_PUBLIC_RECAPTCHA_V2_INVISIBLE_SITE_KEY
              : process.env.NEXT_PUBLIC_RECAPTCHA_V2_SITE_KEY
          }
          onChange={(value) => {
            setRecaptchaTokenV2(value);
          }}
        />
      </>
    );

  return (
    <input
      type="hidden"
      id="recaptcha-token"
      name="recaptcha-token"
      value={recaptchaToken}
      onChange={() => {}}
    />
  );
});
