import { PropsWithChildren } from "react";
import { categories } from "../../../lib/config/categories";
import { Button, ButtonContainer } from "../button";
import { Container } from "../container";
import classes from "./categories.module.css";

interface CategoriesProps {
  title?: string;
  description: string;
  limit?: number;
}

const IconUp = (
  <svg xmlns="http://www.w3.org/2000/svg" width="49" height="49">
    <circle cx="24.5" cy="24.5" r="24.5" fill="#00325a" />
    <g
      fill="none"
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    >
      <path d="M24.573 37.565V11.582" />
      <path d="M11.582 24.573l12.991-12.991 12.992 12.991" />
    </g>
  </svg>
);

const Categories = ({
  title,
  description,
  limit,
}: PropsWithChildren<CategoriesProps>) => {
  let visibleCategories = categories;

  if (limit) {
    visibleCategories = visibleCategories.slice(0, limit);
  }

  const CategoryBlocks = visibleCategories.map((category) => (
    <div
      key={`category_${category.title}`}
      className={classes.marketing__categories_grid_item}
      style={{ backgroundImage: `url(${category.imageURL})` }}
    >
      <input
        className={classes.arrowCheckbox}
        type="checkbox"
        id={`category_${category.title}`}
        name={`category_${category.title}`}
      />
      <label htmlFor={`category_${category.title}`}>{IconUp}</label>
      <div className={classes.marketing__categories_grid_item_content}>
        <strong dangerouslySetInnerHTML={{ __html: category.title }}></strong>
        <p>{category.description}</p>
      </div>
    </div>
  ));

  const MaybeShowMore = limit && (
    <ButtonContainer>
      <Button buttonText="See More" buttonHref="/categories" isBlue isWide />
    </ButtonContainer>
  );

  return (
    <div className={classes.marketing__categories}>
      {title && <h2>{title}</h2>}
      <p className={classes.marketing__categories_description}>{description}</p>
      <div className={classes.marketing__categories_prompt}>
        Click on the arrows to find out more!
      </div>
      <Container>
        <div className={classes.marketing__categories_grid}>
          {CategoryBlocks}
        </div>
        {MaybeShowMore}
      </Container>
    </div>
  );
};

export { Categories };
